import React from 'react'

import { Checkbox } from 'components/Forms'
import { Button, Box } from 'components'
import { Badge } from 'components/Forms/Forms'

const MultiSelectWrapper = ({ hasError, isValid, ...rest }) => {
  return (
    <Box
      {...rest}
      sx={{
        display: 'inline-flex',
        position: 'relative',
        flexDirection: 'column',
        padding: '20px',
        backgroundColor: '#FFFBFC',
        border: '1px solid #BFC7D3',
        borderRadius: '3px',
        ...(hasError && {
          borderColor: '#DB5461',
        }),
        ...(isValid && {
          borderColor: '#83B692',
        }),
      }}
    />
  )
}

class MultiSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      valueStates: [],
      numberOfSelectedValues: 0,
    }
    this.onToggleAll = this.onToggleAll.bind(this)
    this.onChangeHandler = this.onChangeHandler.bind(this)
  }

  onToggleAll(event) {
    event.preventDefault()
    const { values } = this.props
    const { numberOfSelectedValues } = this.state

    const areAllSelected = values.length === numberOfSelectedValues
    if (areAllSelected) {
      this.setState({
        valueStates: new Array(values.length).fill(false),
        numberOfSelectedValues: 0,
      })
    } else {
      this.setState({
        valueStates: new Array(values.length).fill(true),
        numberOfSelectedValues: values.length,
      })
    }
  }

  onChangeHandler(event) {
    this.setState(({ valueStates }) => {
      const valueStatesCopy = [...valueStates]
      valueStatesCopy[event.target.dataset.index] = event.target.checked
      return { valueStates: valueStatesCopy }
    })

    if (event.target.checked) {
      this.setState(({ numberOfSelectedValues }) => {
        return { numberOfSelectedValues: numberOfSelectedValues + 1 }
      })
    } else {
      this.setState(({ numberOfSelectedValues }) => {
        return { numberOfSelectedValues: numberOfSelectedValues - 1 }
      })
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.valueStates !== prevState.valueStates) {
      let selectedValues = []
      this.state.valueStates.forEach((valueState, index) => {
        if (valueState) {
          const value = this.props.values[index]
          const valueToAdd = typeof value === 'string' ? value : value.value
          selectedValues = [...selectedValues, valueToAdd]
        }
      })
      this.props.onChange && this.props.onChange(selectedValues)
    }
  }

  /**
   * Handling the preselectedValues here.
   */
  componentDidMount() {
    const { preselectedValues = [], values } = this.props
    const preselectedValuesPositions = preselectedValues.map((initialValue) =>
      values.findIndex((value) => {
        if (typeof value === 'string') {
          return value === initialValue
        } else {
          return value.value === initialValue
        }
      })
    )
    this.setState({
      valueStates: preselectedValuesPositions.reduce(
        (array, currentPosition) => {
          const updatedArray = [...array]
          updatedArray[currentPosition] = true
          return updatedArray
        },
        []
      ),
      numberOfSelectedValues: preselectedValues.length,
    })
  }

  render() {
    const {
      values,
      onChange,
      hasError,
      isValid,
      disabled,
      selectAllText,
      deselectAllText,
      onFocus,
      onBlur,
      badge,
      ...checkboxProps
    } = this.props

    const { numberOfSelectedValues, valueStates } = this.state

    const areAllSelected = values.length === numberOfSelectedValues
    return (
      <MultiSelectWrapper hasError={hasError} isValid={isValid}>
        {badge && <Badge label={badge} />}
        <Button
          variant="outlineMuted"
          disabled={disabled}
          onClick={this.onToggleAll}
          onFocus={onFocus}
          onBlur={onBlur}
          sx={{ marginBottom: '12px' }}
        >
          {areAllSelected
            ? deselectAllText || 'Alles abwählen'
            : selectAllText || 'Alles auswählen'}
        </Button>
        {values.map((value, index) => {
          const label = typeof value === 'string' ? value : value.label
          return (
            <Checkbox
              key={label}
              data-index={index}
              checked={valueStates[index]}
              onChange={this.onChangeHandler}
              style={{
                marginTop: '20px',
              }}
              disabled={disabled}
              onFocus={onFocus}
              onBlur={onBlur}
              label={label}
              {...checkboxProps}
            />
          )
        })}
      </MultiSelectWrapper>
    )
  }
}

export default MultiSelect
